$(function(){
    $(".sidebar-menu > li.with-submenu").on('click', function(e){
        if(e.target.matches('.submenu-link, .subMenuText')){
            return;
        }
        
        if($(this).hasClass('open')){
            // $(this).removeClass('visible');
            collapseSection($(this).find('.treeview-menu')[0]);
            setTimeout(() => {
                $(this).removeClass('open');
            }, 300);
        } else {
            let el = $(".sidebar-menu > li.open:not(.activeMainMenu)");
            if(el.length > 0){
                collapseSection(el.find('.treeview-menu')[0]);
                // el.find('.treeview-menu').css({height: 0});
                setTimeout(function(){
                    el.removeClass('open visible');
                }, 300)
            }
            $(this).addClass('open');
            expandSection($(this).find('.treeview-menu')[0]);
            
            // setTimeout(() => {
            //     $(this).addClass('visible');
            // }, 10);
        }
     
    });

    function collapseSection(element) {
        // get the height of the element's inner content, regardless of its actual size
        var sectionHeight = element.scrollHeight;
        
        // temporarily disable all css transitions
        var elementTransition = element.style.transition;
        element.style.transition = '';
        
        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we 
        // aren't transitioning out of 'auto'
        requestAnimationFrame(function() {
          element.style.height = sectionHeight + 'px';
          element.style.transition = elementTransition;
          
          // on the next frame (as soon as the previous style change has taken effect),
          // have the element transition to height: 0
          requestAnimationFrame(function() {
            element.style.height = 0 + 'px';
          });
        });
        
        // mark the section as "currently collapsed"
        element.setAttribute('data-collapsed', 'true');
      }
      
      function expandSection(element) {
        // get the height of the element's inner content, regardless of its actual size
        var sectionHeight = element.scrollHeight;
        // console.log(sectionHeight);
        
        // have the element transition to the height of its inner content
        element.style.height = sectionHeight + 'px';
      
        // when the next css transition finishes (which should be the one we just triggered)
        element.addEventListener('transitionend', function(e) {
          // remove this event listener so it only gets triggered once
          element.removeEventListener('transitionend', arguments.callee);
          
          // remove "height" from the element's inline styles, so it can return to its initial value
        //   element.style.height = null;
        });
        
        // mark the section as "currently not collapsed"
        element.setAttribute('data-collapsed', 'false');
      }

    /* on hover out slide down the profile menu. Cannot be fixed with css as is using jquery slide method */
    $(".sidebar").hover(function(e){}, function(e){
        // console.log("out");
        if($('body').hasClass('btn-slide')){
            $(".profileMenuClass").hide();
        }
    })


})